import React from "react"
import { graphql, Link } from "gatsby"

export default function Blog({ data }) {
  const { posts } = data.blog

  return (
    <div>
      <h1>Sportbenefit Blog</h1>

      {posts.map((post) => (
        <article key={post.id}>
          <Link state={{ dropdownLink: true }} to={post.fields.slug}>
            <h2>{post.frontmatter.title}</h2>
          </Link>
          <small>
            {post.frontmatter.author}, {post.frontmatter.date}
          </small>
          <p>{post.excerpt}</p>
        </article>
      ))}
    </div>
  )
}

export const pageQuery = graphql`
  query LtEnBlogsQuery {
    blog: allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(lt_en/(blog))/" } }
    ) {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          date(fromNow: true)
          title
          author
        }
        excerpt
        id
      }
    }
  }
`
